@import '../../../../adiram/src/adiram/scss/base/variables';
@import '../../../../adiram/src/adiram/scss/base/mixins';

.main {
  .section {
    background-size: cover;

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .section-content {
      z-index: 10;
      position: relative;
      height: 100%;

      .select-theme {
        width: 240px;
        padding: 10px 0;
      }

      .video-content {
        z-index: 10;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .overlay {
          @include default-overlay(.2, 0);
          height: 100vh;
        }

        .subtitle {
          text-align: center;
          z-index: 1;

          p {
            margin-left: 0 !important;
          }
        }

        .explore {
          position: absolute;
          bottom: 50px;
          text-align: center;
          color: white;
          font-style: italic;

          .stats {
            font-size: 24px;
            margin-bottom: 0;
          }

          #explore-button {
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            width: 50px;
            height: 50px;
            border: 0;
            background-color: $nature_color;
            color: white;
            &:hover {
              opacity: .8;
            }
          }
        }
      }

      .subtitle {
        margin-left: 10%;

        p {
          line-height: 1;
          font-size: 4rem;
          color: white;
        }

        :not(:first-child) {
          margin-left: 45px;
        }
      }

      .triangles {
        .theme-triangle {
          &.nature {
            @include triangle($nature_color, 14vw, 18vw);
          }
          &.escapes {
            @include triangle($escapes_color, 14vw, 18vw);
          }
          &.flavors {
            @include triangle($flavors_color, 14vw, 18vw);
          }
          &.culture {
            @include triangle($culture_color, 14vw, 18vw);
          }
          z-index: 1;
          position: absolute;
          bottom: 0;
          right: 20vw;

          p {
            text-transform: uppercase;
            font-size: 1.5rem;
            color: white;
          }
        }

        .image-triangle {
          @include triangle(transparent, 26vw, 32vw);
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    .overlay {
      @include default-overlay(.3);
    }
  }
}

.scroll-spy {
  z-index: 100;
  position: fixed;
  top: calc(50% - 136px);
  right: 0;

  $elements: 5;
  @for $i from 0 to $elements {
    div:nth-child(#{$i + 1}) {
      bottom: $i * 45px;
    }
  }

  div {
    position: relative;
    width: 90px;
    height: 90px;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &.focus {
      background-color: rgba(0, 0, 0, 0.7);
    }

    &.active {
      &[data-theme='nature'] {
        background-color: $nature_color;
      }
      &[data-theme='escapes'] {
        background-color: $escapes_color;
      }
      &[data-theme='flavors'] {
        background-color: $flavors_color;
      }
      &[data-theme='culture'] {
        background-color: $culture_color;
      }
      &.menu-search {
        background-color: $primary_color;
      }
    }
  }

  .triangle-left {
    clip-path: polygon(100% 0, 35% 50%, 100% 100%);

    img {
      position: absolute;
      right: 10px;
    }
  }

  .triangle-right {
    clip-path: polygon(35% 0, 100% 50%, 35% 100%);

    img {
      position: absolute;
      left: 40px;
    }
  }

  .home_menu_nature {
    height: 30px;
  }
}

@include media-breakpoint-down(lg) {
  .triangles {
    img {
      height: 40px;
    }
  }
}

// New Navbar custom breakpoint
@media (max-width: 1304px) {
  .triangle-logo {
    display: flex;
  }

  .scroll-spy {
    z-index: 99;
  }

  .menu-white-rsp {
    background-color: white !important;
  }

  .navbar {
    background-color: transparent;
  }

  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }

  .main {
    .section {
      .section-content {
        .subtitle {
          p {
            font-size: 3rem;
          }
        }

        .triangles {
          .theme-triangle {
            &.nature {
              @include triangle($nature_color, 25vw, 32vw);
            }
            &.escapes {
              @include triangle($escapes_color, 25vw, 32vw);
            }
            &.flavors {
              @include triangle($flavors_color, 25vw, 32vw);
            }
            &.culture {
              @include triangle($culture_color, 25vw, 32vw);
            }
            p {
              font-size: 1rem;
            }
            right: 28vw;
          }

          .image-triangle {
            @include triangle(transparent, 38vw, 50vw);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          img {
            height: initial;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  // Shrink triangle top left corner
  .triangle-logo {
    display: flex;
    width: 235px;
    height: 200px;

    img {
      width: 150px;
    }
  }

  .main {
    .section {
      .section-content {
        .subtitle {
          p {
            font-size: 2rem;
          }

          :not(:first-child) {
            margin-left: 0;
          }
        }

        .triangles {
          img {
            height: 40px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .main {
    .section {
      .section-content {
        .subtitle {
          p {
            font-size: 1.5rem;
          }
        }

        .triangles {
          img {
            height: 32px;
          }
        }
      }
    }
    .section-2 .subtitle,
    .section-3 .subtitle,
    .section-4 .subtitle,
    .section-5 .subtitle {
      margin-right: 50px;
      margin-top: 50px;
    }
  }

  .select-theme {
    width: 200px !important;
  }
}
