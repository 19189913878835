//  MEDIA QUERY
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin media-breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin media-breakpoint-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {

    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

//  TRIANGLE
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin triangle($color, $h, $w) {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  height: $h;
  width: $w;
  background-color: $color;
}

//  OVERLAY
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin default-overlay($opacity: .5, $top: 0) {
  position: absolute;
  top: $top;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: $opacity;
  background-color: black;
}